import {
  FetchMaterialCategoriesCacheKey,
  FetchFinMaterialCategoriesFilters
} from '../../materialsTypes';

import {
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../categories/categoriesTypes';

import {
  FETCH_MATERIAL_CATEGORIES_QUERY,
  FetchMaterialCategoriesQueryResponse
} from '../../queries/fetchMaterialCategories.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.MATERIAL_CATEGORIES }
};
const initialSort = [FetchCategoriesSortTypes.NAME_ASC];
const options = {
  staleTime: 1000 * 60 * 60
};

interface MaterialCategoriesOptions {
  cacheKey?: FetchMaterialCategoriesCacheKey;
  addInitialFilters?: FetchFinMaterialCategoriesFilters;
}

function useMaterialCategories({
  cacheKey,
  addInitialFilters
}: MaterialCategoriesOptions = {}) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchMaterialCategoriesQueryResponse>({
    query: FETCH_MATERIAL_CATEGORIES_QUERY,
    cacheKey,
    initialFilters,
    initialSort,
    initialLimit: 1000,
    options
  });

  return {
    materialCategories: categories,
    materialCategoriesErrorMessage: categoriesError,
    materialCategoriesFetched: categoriesFetched,
    materialCategoriesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useMaterialCategories;
