import React, { useCallback } from 'react';

import { I18nText } from '../../../types';

import { Translate } from '../../../helpers/Translate';

type PureFormButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

interface PureFormButtonHelperDefaultProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  form: string;
  onClick?: PureFormButtonHelperOnClick;
  onMouseEnter?: () => void;
}

interface PureFormButtonHelperWithI18nProps {
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureFormButtonHelperWithTextProps {
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureFormButtonHelperProps = PureFormButtonHelperDefaultProps &
  (PureFormButtonHelperWithI18nProps | PureFormButtonHelperWithTextProps);

function PureFormButtonHelper({
  className,
  disabled,
  id,
  form,
  onClick,
  onMouseEnter,
  text,
  i18nText,
  i18nTextClassName
}: PureFormButtonHelperProps) {
  {
    const handleClick = useCallback<PureFormButtonHelperOnClick>(
      (e) => {
        e.preventDefault();
        onClick?.(e);
      },
      [onClick]
    );

    return (
      <button
        id={id}
        className={className}
        disabled={disabled}
        form={form}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        type="button"
      >
        {i18nText && i18nTextClassName ? (
          <span className={i18nTextClassName}>
            <Translate id={i18nText} />
          </span>
        ) : null}
        {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
        {text}
      </button>
    );
  }
}

export default PureFormButtonHelper;
