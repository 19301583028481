import {
  LifestyleUUID,
  LifestyleClientID,
  LifestyleID,
  LifestyleCategoryID
} from './lifestylesTypes';

import { TeamNanoID } from '../teams/teamsTypes';
import { ProductID } from '../products/productsTypes';

export class LifestyleCache {
  static indexCacheKey() {
    return 'lifestyles';
  }

  static showCacheKey() {
    return 'lifestyle';
  }

  static categoriesCacheKey() {
    return 'lifestyleCategories';
  }

  static notEmptyCategoriesCacheKey() {
    return 'notEmptyLifestyleCategories';
  }

  static categoriesLibraryCacheKey(clientIdsPart: string) {
    return `lifestyleCategoriesLibrary-${clientIdsPart}`;
  }

  static libraryCacheKey(clientId: LifestyleClientID) {
    return `lifestylesLibrary-${clientId}`;
  }

  static companyLibraryCacheKey(companyNanoId: TeamNanoID) {
    return `company-lifestyles-library-${companyNanoId}`;
  }

  static companyLibraryTotalCountCacheKey(companyNanoId: TeamNanoID) {
    return `company-lifestyles-library-total-count-${companyNanoId}`;
  }

  static categoryCacheKey() {
    return 'lifestylesCategory';
  }

  static stylesFilterCacheKey() {
    return 'lifestyleStylesFilter';
  }

  static stylesLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleStylesLibraryFilter-${clientIdsPart}`;
  }

  static gammasFilterCacheKey() {
    return 'lifestyleGammasFilter';
  }

  static gammasLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleGammasLibraryFilter-${clientIdsPart}`;
  }

  static tonesFilterCacheKey() {
    return 'lifestyleTonesFilter';
  }

  static tonesLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleTonesLibraryFilter-${clientIdsPart}`;
  }

  static materialsFilterCacheKey() {
    return 'lifestyleMaterialsFilter';
  }

  static materialsLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleMaterialsLibraryFilter-${clientIdsPart}`;
  }

  static colorsFilterCacheKey() {
    return 'lifestyleColorsFilter';
  }

  static colorsLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleColorsLibraryFilter-${clientIdsPart}`;
  }

  static premiumLibraryFilterCacheKey(clientIdsPart: string) {
    return `premiumLibraryFilterCacheKey-${clientIdsPart}`;
  }

  static authorsFilterCacheKey() {
    return 'lifestyleAuthorFilter';
  }

  static authorsLibraryFilterCacheKey(clientId: LifestyleClientID) {
    return `lifestyleAuthorsLibraryFilter-${clientId}`;
  }

  static authorsSelectedFilterCacheKey() {
    return 'lifestyleAuthorsSelectedFilter';
  }
  static typesFilterCacheKey() {
    return 'lifestyleTypesFilter';
  }

  static typesLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleTypesLibraryFilter-${clientIdsPart}`;
  }

  static typesSelectedFilterCacheKey() {
    return 'lifestyleTypesSelectedFilter';
  }

  static showQueryKey(lifestyleUuid: LifestyleUUID) {
    return ['lifestyle', lifestyleUuid];
  }

  static indexSelectCacheKey(fieldName: string) {
    return `lifestyles-select-${fieldName}`;
  }

  static defaultSelectCacheKey(fieldName: string) {
    return `lifestyles-default-select-${fieldName}`;
  }

  static productAssociatedAsset(productId: ProductID) {
    return `product-associated-asset-${productId}`;
  }

  static categoryPreview(lifestyleCategoryId: LifestyleCategoryID) {
    return `lifestyle-category-preview-${lifestyleCategoryId}`;
  }

  static lifestyleAssociatedAsset(lifestyleId: LifestyleID) {
    return `lifestyle-associated-asset-${lifestyleId}`;
  }

  static lifestylesLastBasePath() {
    return `lifestyles-last-base-path`;
  }
}
