import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import { TextFilterType } from '../../../../types';
import { FetchFinCategoriesFiltersLifestyleFilters } from '../../../categories/categoriesTypes';

import {
  FETCH_LIFESTYLE_CATEGORIES_QUERY,
  FetchLifestyleCategoriesQueryResponse
} from '../../queries/fetchLifestyleCategories.query';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';
import {
  FetchLifestyleCategoriesCacheKey,
  FetchLifestylesFilters
} from '../../lifestylesTypes';

const defaultInitialFilters: { scope: TextFilterType } = {
  scope: {
    eq: 'scene_rooms'
  }
};
const initialSort = ['CREATED_AT_ASC'];
const options = {
  staleTime: 1000 * 60 * 60,
  withoutPrefetch: true
};

const scope = 'categories';

function transformRoomsByLifestyle(
  roomsByLifestyle: FetchLifestylesFilters
): FetchFinCategoriesFiltersLifestyleFilters {
  const blocked = roomsByLifestyle?.blocked;
  const favorite = roomsByLifestyle?.favorite;
  const nda = roomsByLifestyle?.nda;
  const styleId = roomsByLifestyle?.styleIds
    ? { in: roomsByLifestyle.styleIds }
    : undefined;
  const materialId = roomsByLifestyle?.materialIds
    ? { in: roomsByLifestyle.materialIds }
    : undefined;
  const lifestyleClientId = roomsByLifestyle?.clientIds
    ? { in: roomsByLifestyle.clientIds }
    : undefined;
  const gammaId = roomsByLifestyle?.gammaId;
  const toneId = roomsByLifestyle?.toneId;
  const mainColorId = roomsByLifestyle?.mainColorIds
    ? { in: roomsByLifestyle.mainColorIds }
    : undefined;
  const authorId = roomsByLifestyle?.authorId;

  return {
    blocked,
    favorite,
    materialId,
    nda,
    styleId,
    lifestyleClientId,
    gammaId,
    toneId,
    mainColorId,
    authorId
  };
}

interface FinLifestyleCategoriesOptions {
  cacheKey?: FetchLifestyleCategoriesCacheKey;
  roomsByLifestyle?: FetchLifestylesFilters;
}

function useFinLifestyleCategories({
  cacheKey,
  roomsByLifestyle: initRoomsByLifestyle
}: FinLifestyleCategoriesOptions = {}) {
  const roomsByLifestyle = transformRoomsByLifestyle(initRoomsByLifestyle);

  const initialFilters = {
    ...defaultInitialFilters,
    roomsByLifestyle
  };

  const {
    items,
    itemsError,
    isFetched,
    isPlaceholderData,
    changeItemsFilters
  } = useFinIndexQuery<FetchLifestyleCategoriesQueryResponse>({
    query: FETCH_LIFESTYLE_CATEGORIES_QUERY,
    cacheKey,
    initialFilters,
    initialSort,
    initialLimit: 1000,
    scope,
    options
  });

  const prevRoomsByLifestyle = usePreviousValue(initRoomsByLifestyle);

  useEffect(() => {
    if (!isEqual(prevRoomsByLifestyle, initRoomsByLifestyle)) {
      changeItemsFilters({
        roomsByLifestyle: transformRoomsByLifestyle(initRoomsByLifestyle)
      });
    }
  }, [changeItemsFilters, initRoomsByLifestyle, prevRoomsByLifestyle]);

  return {
    lifestyleCategories: items,
    lifestyleCategoriesErrorMessage: itemsError,
    lifestyleCategoriesFetched: isFetched,
    lifestyleCategoriesIsPlaceholderData: isPlaceholderData,
    changeLifestyleCategoriesFilters: changeItemsFilters
  };
}

export default useFinLifestyleCategories;
