import { useMemo } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import find from 'lodash/find';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import {
  FetchProductsFilters,
  ProductClientID
} from '../../../products/productsTypes';
import { ThreeDStockIndexPageProductsCategoriesNavCategories } from '../../components/menus/ThreeDStockIndexPageProductsCategoriesNav/ThreeDStockIndexPageProductsCategoriesNav.types';

import { FetchProductCategoriesQueryResponse } from '../../../products/queries/fetchProductCategories.query';

import { useFinProductCategories } from '../../../products/hooks/useFinProductCategories';
import { useProductCategories } from '../../../products/hooks/useProductCategories';
import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';

import { ProductCache } from '../../../products/ProductCache';

interface ThreeDStockProductsCategoriesOptions {
  clientIds?: ProductClientID[];
  productsFilters?: FetchProductsFilters;
}

function useThreeDStockProductsCategories({
  productsFilters
}: ThreeDStockProductsCategoriesOptions) {
  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useFinProductCategories({
    cacheKey: ProductCache.notEmptyCategoriesCacheKey(),
    facilityGroupsByProduct: omit(productsFilters, [
      'productCategoryId',
      'productParentCategoryIds'
    ])
  });

  const {
    productCategories: allProductCategories,
    productCategoriesErrorMessage: allProductCategoriesErrorMessage,
    productCategoriesFetched: allProductCategoriesFetched,
    productCategoriesIsPlaceholderData: allProductCategoriesIsPlaceholderData
  } = useProductCategories({
    cacheKey: ProductCache.categoriesCacheKey()
  });

  useShowToastOnErrorChange({
    error: productCategoriesErrorMessage || allProductCategoriesErrorMessage
  });

  const productCategoriesWithParents = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      productCategories?.map((category) => category.parent)
    );

    const parentWithParentCategories = compact(
      parentCategories?.map(
        (category) =>
          find(allProductCategories, { id: category.id }) || category
      )
    );

    return sortBy(
      uniqBy([...productCategories, ...parentWithParentCategories], 'id'),
      'name'
    );
  }, [allProductCategories, productCategories]);

  const parentCategories =
    useMemo<ThreeDStockIndexPageProductsCategoriesNavCategories>(
      () =>
        sortBy(
          filter(productCategories, (category) => !category.parent),
          'localizedName'
        ),

      [productCategories]
    );

  return {
    parentCategories,
    productCategoriesWithParents,
    productCategoriesErrorMessage:
      productCategoriesErrorMessage || allProductCategoriesErrorMessage,
    productCategoriesFetched:
      productCategoriesFetched && allProductCategoriesFetched,
    productCategoriesIsPlaceholderData:
      productCategoriesIsPlaceholderData &&
      allProductCategoriesIsPlaceholderData
  };
}

export default useThreeDStockProductsCategories;
