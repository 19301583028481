import { useCallback } from 'react';
import { Listbox } from '@headlessui/react';
import toString from 'lodash/toString';
import cl from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { I18nText } from '../../types';
import { IconsEnum } from '../../assets/icons/types';

import { useSelectPopper } from './hooks/useSelectPopper';
import { useTranslate } from '../../common/hooks/useTranslate';

import { Icon } from '../Icon';
import { Translate } from '../Translate';

import { SelectDefaultValueType, SelectOptionType } from './Select.types';

const listBoxOptionClassName = ({ active }) =>
  cl(
    'cursor-default select-none relative py-2 pl-8 pr-4 hover:text-gray-900 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800',
    {
      'text-white bg-blue-600': active,
      'text-gray-900 dark:text-gray-100': !active
    }
  );

export type SelectProps<ValueType = SelectDefaultValueType> = {
  disabled?: boolean;
  options: SelectOptionType<ValueType>[];
  i18nLabel?: I18nText;
  labelClassName?: string;
  onChange: (value: ValueType) => void;
  value?: ValueType | null;
  i18nPlaceholder?: I18nText;
  error?: string | null;
  showError?: boolean;
  showErrorIcon?: boolean;
  errorClassName?: string;
};

function Select<ValueType = SelectDefaultValueType>({
  disabled,
  options,
  i18nLabel,
  labelClassName,
  value,
  onChange,
  i18nPlaceholder,
  error,
  showError = true,
  showErrorIcon = true,
  errorClassName
}: SelectProps<ValueType>) {
  const { t } = useTranslate();

  const handleOnChange = useCallback<(value: ValueType) => void>(
    (value) => onChange && onChange(value),
    [onChange]
  );

  const {
    setReferenceElement,
    setPopperElement,
    popperStyles,
    popperAttributes
  } = useSelectPopper();

  return (
    <div>
      <Listbox<undefined, ValueType>
        disabled={disabled}
        value={value}
        onChange={handleOnChange}
      >
        {i18nLabel ? (
          <Listbox.Label
            className={
              labelClassName ||
              'block text-sm font-medium text-gray-700 dark:text-gray-300'
            }
          >
            <Translate id={i18nLabel} />
          </Listbox.Label>
        ) : null}

        <div className="mt-1 relative">
          <Listbox.Button
            className={cl(
              'z-0 relative w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm',
              {
                'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:focus:border-red-500 dark:border-red-700 dark:placeholder-red-600 dark:bg-gray-800 dark:text-red-500':
                  error
              }
            )}
            ref={setReferenceElement}
          >
            <span className="h-5 block truncate">
              {isEmpty(get(find(options, ['value', value]), 'i18nLabel'))
                ? get(find(options, ['value', value]), 'label')
                : t(
                    get(
                      find(options, ['value', value]),
                      'i18nLabel',
                      i18nPlaceholder || '---'
                    )
                  )}
            </span>
            <span className="block truncate" />
            {error && showError && showErrorIcon ? (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <Icon
                  className="h-5 w-5 text-red-500"
                  icon={IconsEnum.EXCLAMATION_CIRCLE}
                />
              </div>
            ) : (
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <Icon
                  className="h-5 w-5 text-gray-400"
                  icon={IconsEnum.SELECTOR}
                />
              </span>
            )}
          </Listbox.Button>

          <Listbox.Options
            className="z-10 bg-white dark:bg-gray-850 border border-gray-300 dark:border-gray-700 shadow-lg dark: dark:glow-md rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            ref={setPopperElement}
            style={popperStyles.popper}
            {...popperAttributes.popper}
          >
            {options.map((option) => (
              <Listbox.Option
                key={toString(get(option, 'value'))}
                className={listBoxOptionClassName}
                value={get(option, 'value')}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={cl('block truncate', {
                        'font-semibold': selected,
                        'font-normal': !selected
                      })}
                    >
                      {isEmpty(get(option, 'i18nLabel'))
                        ? get(option, 'label')
                        : t(get(option, 'i18nLabel'))}
                    </span>

                    {selected ? (
                      <span
                        className={cl(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          {
                            'text-white': active,
                            'text-blue-600': !active
                          }
                        )}
                      >
                        <Icon icon={IconsEnum.CHECK} />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
      {error && showError && (
        <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
          {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
        </p>
      )}
    </div>
  );
}

export default Select;
