import compact from 'lodash/compact';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import { CategoryID } from '../../../categories/categoriesTypes';

import { FetchProductsFilters } from '../../productsTypes';

interface GetChangeProductsFiltersCategoriesDataCategoryType {
  id: CategoryID;
  parent?: {
    id: CategoryID;
  };
}

interface GetChangeProductsFiltersCategoriesDataOptions {
  productCategory: GetChangeProductsFiltersCategoriesDataCategoryType | null;
  categories: GetChangeProductsFiltersCategoriesDataCategoryType[];
}

interface GetChangeProductsFiltersCategoriesDataReturn {
  changeFilters: FetchProductsFilters;
  resetFilters: string[];
}

function getChangeProductsFiltersCategoriesData({
  productCategory,
  categories
}: GetChangeProductsFiltersCategoriesDataOptions): GetChangeProductsFiltersCategoriesDataReturn {
  // level1
  const parentCategoryId = productCategory?.parent?.id || null;
  // level1 ? level2 : level1.0
  const currentCategoryId = productCategory?.id || null;

  // level1 ? level3 : level2.0
  const childCategoryIds = map(
    filter(categories, (category) => category.parent?.id === currentCategoryId),
    'id'
  );

  // level1 ? null : level3
  const childrensChildrenCategoryIds = parentCategoryId
    ? []
    : map(
        filter(categories, (category) =>
          some(childCategoryIds, (childId) => category.parent?.id === childId)
        ),
        'id'
      );

  // level1 ? level2 : level1.0
  const childAsParentsCategoryIds = filter(childCategoryIds, (childId) =>
    some(categories, (category) => category.parent?.id === childId)
  );

  const productParentCategoryIds = parentCategoryId
    ? // has level1
      compact([
        parentCategoryId,
        !isEmpty(childCategoryIds) && currentCategoryId,
        ...childAsParentsCategoryIds
      ])
    : // no level1
      undefined;

  const allChildrenCategoryIds = parentCategoryId
    ? // has level1
      childCategoryIds
    : // no level1
      uniq([...childCategoryIds, ...childrensChildrenCategoryIds]);

  const productCategoryIds = compact([
    currentCategoryId,
    ...allChildrenCategoryIds
  ]);

  const changeFilters: FetchProductsFilters = {
    productParentCategoryIds,
    productCategoryId: { in: productCategoryIds }
  };

  const resetFilters = compact([
    !isEmpty(productCategoryIds) ? null : 'productCategoryId',
    !isEmpty(productParentCategoryIds) ? null : 'productParentCategoryIds'
  ]);

  return {
    changeFilters,
    resetFilters
  };
}

export default getChangeProductsFiltersCategoriesData;
