import { useEffect } from 'react';
import throttle from 'lodash/throttle';

import { usePopoverState } from '../../../Popover';

function findScrollContainer(element: HTMLElement): HTMLElement | undefined {
  const parent = element.parentElement;

  if (!parent) {
    return undefined;
  }

  const isParentHasScroll =
    parent.scrollHeight > parent.clientHeight ||
    parent.scrollWidth > parent.clientWidth;

  if (isParentHasScroll) {
    return parent;
  }

  return findScrollContainer(parent);
}

function useDropdownState(closeOnOutOfView?: boolean) {
  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover,
    openPopover
  } = usePopoverState();

  useEffect(() => {
    if (closeOnOutOfView && referencePopoverElement && isPopoverOpen) {
      const scrollContainer = findScrollContainer(referencePopoverElement);

      if (!scrollContainer) {
        return;
      }

      const checkVisibility = () => {
        const elementRect = referencePopoverElement.getBoundingClientRect();
        const containerRect = scrollContainer.getBoundingClientRect();

        const isElementVisible =
          elementRect.bottom > containerRect.top &&
          elementRect.top < containerRect.bottom &&
          elementRect.right > containerRect.left &&
          elementRect.left < containerRect.right;

        if (!isElementVisible) {
          closePopover();
        }
      };

      checkVisibility();

      const handleCheckVisibility = throttle(checkVisibility, 100);

      scrollContainer.addEventListener('scroll', handleCheckVisibility);

      return () => {
        scrollContainer.removeEventListener('scroll', handleCheckVisibility);
      };
    }
  }, [closeOnOutOfView, closePopover, isPopoverOpen, referencePopoverElement]);

  return {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover,
    openPopover
  };
}

export default useDropdownState;
