import React, { ReactNode } from 'react';

type LinkHelperOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

interface LinkHelperProps {
  className?: string;
  href: string;
  title?: string;
  target?: '_blank';
  onClick?: LinkHelperOnClick;
  children: ReactNode;
}

function LinkHelper({
  className,
  href,
  title,
  target,
  onClick,
  children
}: LinkHelperProps) {
  return (
    <a
      href={href}
      title={title}
      className={className}
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

export default LinkHelper;
