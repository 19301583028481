import { useCallback } from 'react';
import { Controller } from 'react-hook-form';

import { Select, SelectDefaultValueType } from '../../Select';

import {
  SelectFieldProps,
  SelectFieldRequiredProps
} from './SelectField.types';

function SelectField<FormDataType, ValueType = SelectDefaultValueType>({
  control,
  disabled,
  name,
  options,
  i18nLabel,
  i18nPlaceholder,
  labelClassName,
  error
}: SelectFieldProps<FormDataType, ValueType> &
  SelectFieldRequiredProps<FormDataType>) {
  const handleRenderSelect = useCallback(
    ({ field: { onChange, value } }) => (
      <Select<ValueType>
        error={error}
        disabled={disabled}
        i18nLabel={i18nLabel}
        labelClassName={labelClassName}
        onChange={onChange}
        options={options}
        value={value}
        i18nPlaceholder={i18nPlaceholder}
      />
    ),
    [error, disabled, i18nLabel, labelClassName, options, i18nPlaceholder]
  );
  return (
    <Controller control={control} name={name} render={handleRenderSelect} />
  );
}

export default SelectField;
