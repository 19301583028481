import compact from 'lodash/compact';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import { CategoryID } from '../../../categories/categoriesTypes';

import { FetchLifestylesFilters } from '../../lifestylesTypes';

interface GetChangeLifestylesFiltersCategoriesDataCategoryType {
  id: CategoryID;
  parent?: {
    id: CategoryID;
  };
}

interface GetChangeLifestylesFiltersCategoriesDataOptions {
  lifestyleCategory: GetChangeLifestylesFiltersCategoriesDataCategoryType | null;
  categories: GetChangeLifestylesFiltersCategoriesDataCategoryType[];
}

interface GetChangeLifestylesFiltersCategoriesDataReturn {
  changeFilters: FetchLifestylesFilters;
  resetFilters: string[];
}

function getChangeLifestylesFiltersCategoriesData({
  lifestyleCategory,
  categories
}: GetChangeLifestylesFiltersCategoriesDataOptions): GetChangeLifestylesFiltersCategoriesDataReturn {
  // level1
  const parentCategoryId = lifestyleCategory?.parent?.id || null;
  // level1 ? level2 : level1.0
  const currentCategoryId = lifestyleCategory?.id || null;

  // level1 ? level3 : level2.0
  const childCategoryIds = map(
    filter(categories, (category) => category.parent?.id === currentCategoryId),
    'id'
  );

  // level1 ? null : level3
  const childrensChildrenCategoryIds = parentCategoryId
    ? []
    : map(
        filter(categories, (category) =>
          some(childCategoryIds, (childId) => category.parent?.id === childId)
        ),
        'id'
      );

  // level1 ? level2 : level1.0
  const childAsParentsCategoryIds = filter(childCategoryIds, (childId) =>
    some(categories, (category) => category.parent?.id === childId)
  );

  const lifestyleParentCategoryIds = parentCategoryId
    ? // has level1
      compact([
        parentCategoryId,
        !isEmpty(childCategoryIds) && currentCategoryId,
        ...childAsParentsCategoryIds
      ])
    : // no level1
      undefined;

  const allChildrenCategoryIds = parentCategoryId
    ? // has level1
      childCategoryIds
    : // no level1
      uniq([...childCategoryIds, ...childrensChildrenCategoryIds]);

  const lifestyleCategoryIds = compact([
    currentCategoryId,
    ...allChildrenCategoryIds
  ]);

  const changeFilters: FetchLifestylesFilters = {
    lifestyleParentCategoryIds,
    lifestyleCategoryId: { in: lifestyleCategoryIds }
  };

  const resetFilters = compact([
    !isEmpty(lifestyleCategoryIds) ? null : 'lifestyleCategoryId',
    !isEmpty(lifestyleParentCategoryIds) ? null : 'lifestyleParentCategoryIds'
  ]);

  return {
    changeFilters,
    resetFilters
  };
}

export default getChangeLifestylesFiltersCategoriesData;
