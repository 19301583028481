import React, { Fragment, ReactNode } from 'react';
import cl from 'classnames';

interface LoadingProps {
  addClass?: string;
  children?: ReactNode;
  className?: string;
  ignoreLoader?: ReactNode;
  loaded?: boolean;
  withoutLoader?: boolean;
  size?: 'xs' | '2xs';
}

function Loading({
  addClass,
  children,
  className,
  ignoreLoader,
  loaded = false,
  withoutLoader = false,
  size
}: LoadingProps) {
  if (!loaded && withoutLoader) {
    return null;
  }

  return (
    <Fragment>
      {ignoreLoader}
      {loaded ? (
        children
      ) : (
        <div className={className || cl('loader-wrap', addClass)}>
          <span className="sr-only">Loading...</span>
          <div
            className={cl('loader', {
              'loader-xs': size === 'xs',
              'loader-2xs': size === '2xs'
            })}
            aria-hidden="true"
          >
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Loading;
