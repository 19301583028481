import { MaterialClientID } from './materialsTypes';

import { TeamNanoID } from '../teams/teamsTypes';

export class MaterialCache {
  static indexCacheKey() {
    return 'materials';
  }

  static showCacheKey() {
    return 'material';
  }

  static categoriesCacheKey() {
    return 'materialCategories';
  }

  static categoriesLibraryCacheKey(clientIdsPart: string) {
    return `materialCategoriesLibrary-${clientIdsPart}`;
  }

  static libraryCacheKey(clientId: MaterialClientID) {
    return `materialsLibrary-${clientId}`;
  }

  static companyLibraryCacheKey(companyNanoId: TeamNanoID) {
    return `company-materials-library-${companyNanoId}`;
  }

  static brandsFilterCacheKey() {
    return 'materialBrandsFilter';
  }

  static brandsLibraryFilterCacheKey(clientIdsPart: string) {
    return `materialBrandsLibraryFilter-${clientIdsPart}`;
  }

  static brandsSelectedFilterCacheKey() {
    return 'materialBrandsSelectedFilter';
  }

  static materialsLastBasePath() {
    return `materials-last-base-path`;
  }
}
